import React, { useMemo } from "react";
import cx from "classnames";
import range from "lodash/range";
import Link from "next/link";
import { useRouter } from "next/router";

import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

import ChevronIcon from "components/Icons/ChevronIcon";

import createPaginationUrl from "./createPaginationUrl";
import RelativeLinkTags from "./RelativeLinkTags";

type Props = {
  className?: string;
  currentPage: number;
  onNext?: (page: number) => void;
  onPageSelect?: (page: number) => void;
  onPrev?: (page: number) => void;
  pageInPath?: boolean;
  showRelativeLinkTags?: boolean;
  totalPages: number;
};

const intercalate = (delim: (string | number)[], arrs: (string | number)[][]) =>
  arrs.reduce((acc, curr) => acc.concat(delim, curr));

const PAGE_BREAKPOINT = 5;
const ELLIPSE = "...";

const PageNavigation: React.FC<Props> = ({
  className,
  currentPage,
  onNext,
  onPageSelect,
  onPrev,
  pageInPath,
  showRelativeLinkTags = true,
  totalPages,
}) => {
  const { asPath } = useRouter();

  const currentPagination = useMemo(() => {
    const totalPagesInt = Number(totalPages);

    const constructPagination = (segments: (string | number)[][]) => {
      const intercalatedSegments = intercalate([ELLIPSE], segments);
      return intercalatedSegments.map((page) => ({
        page,
      }));
    };

    // Total Pages LTE 5
    // no ellipses < 1 2 [3] 4 >
    if (totalPagesInt <= PAGE_BREAKPOINT) {
      return constructPagination([range(1, totalPagesInt + 1)]);
    }

    // Current page LT 5 and total pages GT 5
    // show ellipse only before last page < 1 [2] 3 ... 20 >
    if (totalPagesInt > PAGE_BREAKPOINT && currentPage < PAGE_BREAKPOINT) {
      const windowEnd = Math.max(currentPage + 2, 4);
      return constructPagination([range(1, windowEnd), [totalPagesInt]]);
    }

    // Current page GTE 5 and more than 3 less than total
    // show ellipse on both sides with a window of 3 positions < 1 ... 6 [7] 8 ... 20 >
    if (currentPage >= PAGE_BREAKPOINT && currentPage < totalPagesInt - 3) {
      return constructPagination([
        [1],
        range(currentPage - 1, currentPage + 2),
        [totalPagesInt],
      ]);
    }

    // Current page 3 or less positions away from end
    // show ellipse between 1 and tail of list < 1 ... 16 [17] 18 19 20 >
    const start = Math.min(totalPagesInt - 2, currentPage - 1);
    return constructPagination([[1], range(start, totalPagesInt + 1)]);
  }, [currentPage, totalPages]);

  if (totalPages < 2) {
    return <></>;
  }

  const { path, query } = parseNextRouterAsPath(asPath);

  return (
    <>
      {showRelativeLinkTags && (
        <RelativeLinkTags
          currentPage={currentPage}
          pageInPath={pageInPath}
          totalPages={totalPages}
        />
      )}

      <nav
        aria-label="Navigate between pages"
        className={cx(className, "flex items-center justify-center")}
      >
        <div className="flex gap-2">
          {currentPage !== 1 && (
            <Link
              className="self-center p-2"
              href={createPaginationUrl(
                path,
                query,
                pageInPath,
                currentPage > 2 ? currentPage - 1 : undefined,
              )}
              onClick={() => onPrev?.(currentPage - 1)}
              aria-label="Previous Page"
            >
              <ChevronIcon
                className="inline-block text-green"
                direction="left"
              />
            </Link>
          )}
          <div
            className="flex flex-grow items-center justify-center text-center"
            data-testid="pages-shown"
          >
            {currentPagination.map(({ page }, i) => {
              return page === ELLIPSE ? (
                <span
                  key={`page-nav-ellipse-${i}`}
                  className="rounded-sm w-10 h-10 flex items-center justify-center text-baseline no-underline"
                >
                  {page}
                </span>
              ) : (
                <Link
                  href={createPaginationUrl(
                    path,
                    query,
                    pageInPath,
                    Number(page) > 1 ? page : undefined,
                  )}
                  key={`page-nav-page-${page}`}
                  className={cx(
                    "rounded-sm w-10 h-10 flex items-center justify-center",
                    {
                      "bg-light-grey": page === currentPage,
                      "underline text-green": page !== currentPage,
                    },
                  )}
                  onClick={() => onPageSelect?.(Number(page))}
                  aria-label={`Page ${page}`}
                >
                  {page}
                </Link>
              );
            })}
          </div>
          {currentPage !== Number(totalPages) && (
            <Link
              className="self-center justify-end p-2"
              href={createPaginationUrl(
                path,
                query,
                pageInPath,
                currentPage + 1,
              )}
              onClick={() => onNext?.(currentPage + 1)}
              aria-label="Next Page"
            >
              <ChevronIcon
                className="inline-block text-green"
                direction="right"
              />
            </Link>
          )}
        </div>
      </nav>
    </>
  );
};

export default PageNavigation;
