import React, { ComponentProps } from "react";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { ParsedQs } from "qs";

import { STRAIN_USER_REVIEW_DISCLAIMER } from "constants/disclaimers";
import { Query } from "custom-types/Query";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

import Switch from "components/botanic/Switch/Switch";
import ExpandableFilterOptions from "components/ExpandableFilterOptions";
import { FilterSection } from "components/Filters/FilterSection";
import IconSvg from "components/IconSvg";
import MedicalDisclaimers from "components/MedicalDisclaimers";
import { useIcons } from "components/MenuFilters/hooks";
import { MenuItemFilterSection } from "components/MenuFilters/types/MenuFilters";

import CheckboxSearchList from "../CheckboxSearchList";
import TileLink from "../TileLInk";
import { getUpdatedFilterHref } from "../utils/filterUtils";
import {
  AvailableFilter,
  FilterName,
  FilterType,
  GroupFilter,
  ToggleFilterType,
} from "./types";

const DEFAULT_VISIBLE_TAKE = 9;

const DynamicFilters: React.FC<{
  availableFilters: AvailableFilter[];
  defaultOpenKey?: string;
  sectionPadding?: string;
}> = ({ availableFilters, defaultOpenKey, sectionPadding }) => (
  <>
    {availableFilters.map((filter) => (
      <DynamicFilter
        key={filter.name}
        filter={filter}
        defaultOpenKey={defaultOpenKey}
        sectionPadding={sectionPadding}
      />
    ))}
  </>
);

export const DynamicFilter: React.FC<{
  filter: AvailableFilter;
  defaultOpenKey?: string;
  openSection?: string;
  sectionPadding?: string;
}> = ({ filter, defaultOpenKey, sectionPadding }) => (
  <>
    {filter.type === FilterType.toggle && (
      <ToggleFilter
        filter={filter as ToggleFilterType}
        sectionPadding={sectionPadding}
      />
    )}
    {filter.type === FilterType.tileGroup &&
      (filter as GroupFilter).values.length > 0 && (
        <TileFilters
          filter={filter as GroupFilter}
          defaultOpenKey={defaultOpenKey}
          sectionPadding={sectionPadding}
        />
      )}
    {filter.type === FilterType.checkBoxGroup &&
      (filter as GroupFilter).values.length > 0 && (
        <CheckboxFilters
          filter={filter as GroupFilter}
          defaultOpenKey={defaultOpenKey}
          sectionPadding={sectionPadding}
        />
      )}
  </>
);

const ToggleFilter: React.FC<{
  filter: ToggleFilterType;
  sectionPadding?: string;
}> = ({
  filter: {
    name,
    label,
    sublabel,
    filterIcon,
    labelIcon,
    count = 0,
    showCount = false,
  },
  sectionPadding,
}) => {
  const { asPath, push } = useRouter();
  const { query } = parseNextRouterAsPath<ParsedQs>(asPath);
  const { [name]: appliedFilterValue } = (query.filter as Query) || {};

  const { getIconPath } = useIcons();
  const disabled = count < 1;

  const labelIconClassnames = classNames("self-center", {
    "text-verified": name === MenuItemFilterSection.BrandVerified,
  });

  if (disabled) {
    return null;
  }

  const href = getUpdatedFilterHref(asPath, name, "true");

  return (
    <div
      className={classNames(
        "flex flex-col border-t border-b border-light-grey py-[17px] md:gap-2",
        {
          "md:pb-lg": sublabel,
          "p-lg": sectionPadding,
        },
      )}
    >
      <Link
        href={href}
        className="flex justify-between"
        data-testid="toggle-filter"
      >
        <span className={classNames("flex font-bold")}>
          {filterIcon && (
            <IconSvg
              height="24"
              width="24"
              className="flex-shrink-0 inline mr-xs"
              svgClassName="fill-[var(--color-notification)]"
              filePath={getIconPath(name as MenuItemFilterSection, filterIcon)}
              testId={`${name}-filter-icon`}
            />
          )}
          {label}&nbsp;
          {labelIcon && (
            <IconSvg
              height="16"
              width="16"
              className={labelIconClassnames}
              filePath={getIconPath(name as MenuItemFilterSection, labelIcon)}
            />
          )}
          {showCount && (
            <span className="text-grey text-start leading-none self-center">
              ({count.toLocaleString("en-us")})
            </span>
          )}
        </span>
        <Switch
          checked={appliedFilterValue === "true"}
          checkedBgColor="bg-notification"
          disabled={disabled}
          screenReaderText={label}
          onChange={() => {
            push(href, undefined, { shallow: false });
          }}
        />
      </Link>
      {sublabel && (
        <div className="flex items-center">
          <span className="text-xs font-normal whitespace-pre-wrap text-left text-grey">
            {sublabel}
          </span>
        </div>
      )}
    </div>
  );
};

const CheckboxFilters: React.FC<{
  filter: GroupFilter;
  defaultOpenKey: string | undefined;
  sectionPadding?: string;
}> = ({ filter, defaultOpenKey, sectionPadding }) => {
  const { asPath } = useRouter();
  const { query } = parseNextRouterAsPath<ParsedQs>(asPath);
  const { [filter.name]: appliedFilterValue = [] } =
    (query.filter as Query) || {};

  const selectedValues: string[] = Array.isArray(appliedFilterValue)
    ? appliedFilterValue
    : [appliedFilterValue];

  const options = filter.values.map(({ label, count, value, badge }) => ({
    badge: badge,
    displayName: label,
    docCount: count,
    key: `${filter.name}_${value}`,
    label: label,
    showCount: filter.showCount,
    value: value,
  }));

  return (
    <FilterSection
      title={filter.label}
      numberOfItemsSelected={selectedValues.length}
      openDefault={defaultOpenKey === filter.name}
      open={defaultOpenKey === filter.name}
      sectionPadding={sectionPadding}
    >
      {filter.name === "helps_with" && (
        <MedicalDisclaimers usDisclaimer={STRAIN_USER_REVIEW_DISCLAIMER} />
      )}
      <CheckboxSearchList
        label={filter.label}
        name={filter.name}
        options={options}
      />
    </FilterSection>
  );
};

const TileFilters: React.FC<{
  filter: GroupFilter;
  defaultOpenKey: string | undefined;
  visibleTake?: number;
  sectionPadding?: string;
}> = ({
  filter: { label, name, values, showCount },
  defaultOpenKey,
  visibleTake = DEFAULT_VISIBLE_TAKE,
  sectionPadding,
}) => {
  const { asPath } = useRouter();
  const { query } = parseNextRouterAsPath<ParsedQs>(asPath);
  const { [name]: appliedFilterValue = [] } = (query.filter as Query) || {};

  const selectedValues: string[] = Array.isArray(appliedFilterValue)
    ? appliedFilterValue
    : [appliedFilterValue];

  const options: ComponentProps<typeof DynamicTileFilter>["options"] =
    values.map(({ label, count, value }) => ({
      docCount: count,
      label: label,
      showCount: showCount,
      value: value,
    }));
  const visibleOptions = options.slice(0, visibleTake);
  const hiddenOptions = options.slice(visibleTake);

  return (
    <FilterSection
      title={label}
      numberOfItemsSelected={selectedValues.length}
      openDefault={defaultOpenKey === name}
      open={defaultOpenKey === name}
      sectionPadding={sectionPadding}
    >
      <div className="row" data-testid="tile-filters">
        <div className="flex flex-wrap overflow-auto max-h-80">
          <DynamicTileFilter options={visibleOptions} name={name} />
          {!!hiddenOptions.length && (
            <ExpandableFilterOptions
              expandButtonText={`Show all ${options.length.toLocaleString(
                "en-us",
              )}`}
              className="ml-sm"
            >
              <DynamicTileFilter options={hiddenOptions} name={name} />
            </ExpandableFilterOptions>
          )}
        </div>
      </div>
    </FilterSection>
  );
};

const DynamicTileFilter: React.FC<{
  options: {
    label: string;
    value: string;
    docCount: number;
    showCount: boolean;
  }[];
  name: string;
}> = ({ options, name }) => {
  let rootDir = "";
  switch (name) {
    case FilterName.StrainEffects:
      rootDir = "effects";
      break;
    case FilterName.StrainCategory:
      rootDir = "phenotypes";
      break;
    default:
      break;
  }
  const root = rootDir ? `${rootDir}/` : "";

  return (
    <>
      {options?.map(({ label, value, docCount, showCount }) => (
        <div className="col-1/3 mb-sm" key={value}>
          <TileLink
            iconPath={`${root}${label.replace(/_/g, "-").toLowerCase()}.svg`}
            label={label}
            lowerCase
            count={docCount}
            showCount={showCount}
            name={name}
            value={value}
          />
        </div>
      ))}
    </>
  );
};

export default DynamicFilters;
