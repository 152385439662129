import { stringify as qsStringify } from "qs";

/**
 * leaflyShopStringify
 * Query strings are tricky! There are various ways to represent them, encode them, etc etc.
 * The qs npm package is already superior to NextJS' utilities for query string handling, as it handles deep objects and various config options.
 * This utility seeks to use a set configuration of qs.stringify that meets certain SEO based requirements for /shop:
 *   1. Spaces in value strings should be encoded as '+', not '%20' (favor RFC1738).
 *   2. Arrays should use brackets to indicate themselves, as opposed to merely using identical keys (filter[]=&filter[]= vs filter=&filter=).
 *   3. Arrays should not be formatted with their indexes.
 * @param obj the object you want encoded into a /shop compliant query string.
 * @returns {string} a leafly /shop compliant query string, that meets our SEO requirements.
 */
export const leaflyShopStringify = (obj: Parameters<typeof qsStringify>[0]) =>
  qsStringify(obj, {
    arrayFormat: "brackets",
    format: "RFC1738", // * https://github.com/ljharb/qs?tab=readme-ov-file#rfc-3986-and-rfc-1738-space-encoding
    indices: false,
  });
